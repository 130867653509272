<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.MATERIAL_PROPERTY") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="materialPropertieSchema"
                :model="materialPropertie"
                :options="formOptions"
              ></vue-form-generator>

              <div v-if="isEdit">
                <div class="form-group valid required field-input">
                  <label for=""
                    ><span>Добави нова стойност</span>
                    <!----></label
                  >
                  <v-row>
                    <v-col cols="8">
                      <div class="field-wrap">
                        <small>Стойност:</small><br />
                        <div class="wrapper">
                          <input
                            v-model="new_property"
                            type="text"
                            class="form-control"
                          />
                          <!---->
                        </div>
                        <!---->
                      </div>
                    </v-col>
                    <v-col cols="4"
                      ><br />
                      <v-btn
                        @click="
                          if (new_property) {
                            materialPropertie.values.push({
                              name: new_property
                            });
                            new_property = null;
                          }
                        "
                        class="btn btn-success btn-block btn-elevate kt-login__btn-primary text-white"
                      >
                        {{ $t("BUTTON.ADD") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!---->
                  <!---->
                </div>

                <hr />

                <div class="form-group">
                  <label for="">
                    <span>Стойности: </span>
                  </label>
                </div>

                <v-row
                  v-for="(item, index) in materialPropertie.values"
                  v-bind:key="index"
                >
                  <v-col cols="8">
                    <input
                      v-model="item.name"
                      type="text"
                      class="form-control"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      @click="deleteProperty(item)"
                      class="btn btn-danger btn-block btn-elevate kt-login__btn-primary text-white"
                    >
                      {{ $t("BUTTON.DELETE") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_MATERIALPROPERTIE,
  SAVE_MATERIALPROPERTIE,
  UPDATE_MATERIALPROPERTIE
} from "@/store/materialProperties.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)

import _ from "lodash";

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingMaterialProperties"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      error_messages: [],
      model: "materialProperties",
      new_property: null,
      tab: 0,
      materialPropertie: {
        name: null
      },
      materialPropertieSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!"
            })
          }
        ]
      },
      properties: [],
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Материали - изисквания", route: "/material-properties" },
        { title: current_page }
      ]);
    }, 300);

    if (typeof this.$route.params.id !== "undefined") {
      this.getData();
    }
  },
  methods: {
    deleteProperty: function(property) {
      let vm = this;
      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.materialPropertie.values = _.remove(
            vm.materialPropertie.values,
            function(n) {
              return n !== property;
            }
          );
        },
        onCancel() {}
      });
    },
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_MATERIALPROPERTIE
          : SAVE_MATERIALPROPERTIE;
      this.error_messages = [];

      vm.$store
        .dispatch(action, { payload: vm.materialPropertie })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({ name: "materialPropertiesList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "materialPropertiesList" });
          }
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_MATERIALPROPERTIE, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialPropertie = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "materialPropertiesList" });
          }
        });
    }
  }
};
</script>
