var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      typeof _vm.error_messages === 'object' &&
        Object.keys(_vm.error_messages).length > 0
    )?_c('div',{staticClass:"alert alert-danger"},[_c('ul',[_vm._l((_vm.error_messages),function(error,i){return [_c('span',{key:i},_vm._l((error),function(message,ii){return _c('li',{key:ii},[_vm._v(" "+_vm._s(message)+" ")])}),0)]})],2)]):_vm._e(),_c('div',{staticClass:"tabs"},[_c('v-tabs',{staticClass:"elevation-2",attrs:{"background-color":"light primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("COMPONENTS.MATERIAL_PROPERTY")))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('vue-form-generator',{ref:"form",attrs:{"schema":_vm.materialPropertieSchema,"model":_vm.materialPropertie,"options":_vm.formOptions}}),(_vm.isEdit)?_c('div',[_c('div',{staticClass:"form-group valid required field-input"},[_c('label',{attrs:{"for":""}},[_c('span',[_vm._v("Добави нова стойност")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"field-wrap"},[_c('small',[_vm._v("Стойност:")]),_c('br'),_c('div',{staticClass:"wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_property),expression:"new_property"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.new_property)},on:{"input":function($event){if($event.target.composing)return;_vm.new_property=$event.target.value}}})])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('br'),_c('v-btn',{staticClass:"btn btn-success btn-block btn-elevate kt-login__btn-primary text-white",on:{"click":function($event){if (_vm.new_property) {
                          _vm.materialPropertie.values.push({
                            name: _vm.new_property
                          });
                          _vm.new_property = null;
                        }}}},[_vm._v(" "+_vm._s(_vm.$t("BUTTON.ADD"))+" ")])],1)],1)],1),_c('hr'),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_c('span',[_vm._v("Стойности: ")])])]),_vm._l((_vm.materialPropertie.values),function(item,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"8"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.name),expression:"item.name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(item.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(item, "name", $event.target.value)}}})]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"btn btn-danger btn-block btn-elevate kt-login__btn-primary text-white",on:{"click":function($event){return _vm.deleteProperty(item)}}},[_vm._v(" "+_vm._s(_vm.$t("BUTTON.DELETE"))+" ")])],1)],1)})],2):_vm._e(),_c('v-btn',{staticClass:"btn btn-success btn-elevate kt-login__btn-primary text-white",on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("BUTTON.SAVE"))+" ")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }